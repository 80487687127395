import styled from "styled-components";

export const HeroContainer = styled.div`
  margin-top: 100px;
  font-family: Roboto;
  position: relative;
  background-color: #000000;
  min-height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 768px) {
    min-height: 432px;
    height: 100%;
  }
  .background-image {
    position: absolute;
    width: 100vw;
    height: 100%;
  }
`

export const Container = styled.div`
  position: relative;
  height: 500px;
  min-height: 100%;
  margin:auto;
  max-width: 1170px;
  display: flex;
  ${({ type }) =>
  type.includes('center') && `
  justify-content: center;  
`}
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
    min-height: auto;
  }
`

export const TextContainer = styled.div`
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  max-width: 770px;
  padding: 0 15px;
  ${({ type }) =>
    type.includes('center') && `
    text-align: center;  
  `}
`

export const Gradient = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  background: linear-gradient(90deg, #101828 35.88%, rgba(16, 24, 40, 0.4) 98.71%);
  opacity: 0.7;
  ${({ type }) =>
  type.includes('blue') && `
    background: #0000C0;
    opacity: 0.6;  
  `}
  ${({ type }) =>
  type.includes('dark') && `
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.5) 43.06%, rgba(0, 0, 0, 0) 63.88%), url(.jpg), #000000; 
  `}
`

export const CTAContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  button {
    margin-right: 15px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 15px;
    }
  }
  ${({ type }) =>
  type.includes('center') && `
    justify-content: center;  
  `}
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 15px;
  }
`
